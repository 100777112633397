import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import Publications from './pages/publications/Main';
import Marketing from './pages/marketing/Main';
import About from './pages/marketing/About';
import Guidelines from './pages/marketing/Guidelines';
import UserDashboard from './pages/dashboard/Main';
import ViewPublication from './pages/publications/ViewPublication';
import AdminDashboard from './pages/admin_dashboard/Main';
import Community from './pages/community/Main';
import UserProfile from './pages/user_profile/Main';
import SchoolClass from './pages/school_class/Main';
import GradedAssignment from './pages/graded_assignment/Main';
import InputAssignment from './pages/input_assignment/Main';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_INKBOWL_API_HOST,
//   cache: new InMemoryCache(),
// });

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_INKBOWL_API_HOST}/graphql`
});

const token = process.env.REACT_APP_INKBOWL_API_TOKEN
const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<Marketing />} />
            <Route path="/about" element={<About />} />
            <Route path="/guidelines" element={<Guidelines />} />
            <Route path="/users/:handle" element={<UserProfile />}/>
            <Route path="/publications" element={<Publications />} />  
            <Route path="/publications/:uuid" element={<ViewPublication />}/>
            <Route path="/dashboard" element={<UserDashboard />}/>
            <Route path="/community" element={<Community />}/>
            <Route path="/admin" element={<AdminDashboard />}/>
            <Route path="/classes/:uuid" element={<SchoolClass />}/>
            <Route path="/graded_assignments/:uuid" element={<GradedAssignment />}/>
            <Route path="/input_assignments/:uuid" element={<InputAssignment />}/>
          </Routes>
        </Router>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
