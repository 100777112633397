import React, { useState, useEffect } from 'react';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

// Third Party
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

// Internal
import CenteredLoadingSpinner from '../../components/CenteredLoadingSpinner';
import SharedAppBar from "../../components/SharedAppBar";
import Suggestions from './Suggestions';
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import { InputAssignmentType } from '../../data/types';
import { VIEW_INPUT_ASSIGNMENT } from '../../data/graphql/queries';
import { UPDATE_INPUT_ASSIGNMENT } from '../../data/graphql/mutations';

function Main() {
  const tones: string[] = ['Formal', 'Informal', 'Neutral'];
  const styles: string[] = ['Narrative', 'Descriptive', 'Expository', 'Persuasive'];
  const genres: string[] = ['Fiction', 'Non-Fiction'];

  let { uuid: inputAssignmentUuid } = useParams();

  const { user: auth0User } = useAuth0();
  const { data: inputAssignmentData, error } = useQuery(VIEW_INPUT_ASSIGNMENT, {
    variables: { uuid: inputAssignmentUuid }
  });
  const [updateInputAssignment, {
    error: _UpdateInputAssignmentError,
    data: UpdateInputAssignmentData,
    loading: UpdateInputAssignmentLoading
  }] =
    useMutation(UPDATE_INPUT_ASSIGNMENT);

  const [inputAssignment, setInputAssignment] = React.useState<InputAssignmentType | null>(null);
  const [inputText, setInputText] = useState<string>(inputAssignment?.originalText || '');
  const [submittedText, setSubmittedText] = useState<string>('');

  useEffect(() => {
    if (inputAssignmentData) {
      const assignment = inputAssignmentData.inputAssignment as InputAssignmentType;
      setInputAssignment(assignment);
      setInputText(assignment.originalText);
    }
  }, [inputAssignmentData])

  const handleSubmit = () => {
    if (!inputAssignment) { return; }
    updateInputAssignment({ variables: {
      uuid: inputAssignment.uuid,
      name: inputAssignment.name,
      originalText: inputText,
      tone: inputAssignment.tone,
      style: inputAssignment.style,
      genre: inputAssignment.genre,
    }});

    setSubmittedText(inputText);
  };

  const handleBack = () => {
    setSubmittedText('');
    window.location.reload();
  }

  const handleChange = (field: keyof InputAssignmentType) => (event: any) => {
    if (inputAssignment) {
      setInputAssignment({ ...inputAssignment, [field]: event.target.value });
    }
  }

  if (UpdateInputAssignmentLoading || !inputAssignment) { return <CenteredLoadingSpinner /> }

  const assignmentConfigView = () => {
    if (!inputAssignment) return null;
    return (
      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"} sx={{m: 1}}>
        <Select
          value={inputAssignment.tone}
          onChange={handleChange('tone')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{mr: 2}}
        >
          <MenuItem value=""><em>tone</em></MenuItem>  
          {tones.map((tone: string, index: number) => (
            <MenuItem key={index} value={tone}>{tone}</MenuItem>
          ))}
        </Select>

        <Select
          value={inputAssignment.style}
          onChange={handleChange('style')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{mr: 2}}
        >
          <MenuItem value=""><em>style</em></MenuItem>  
          {styles.map((style: string, index: number) => (
            <MenuItem key={index} value={style}>{style}</MenuItem>
          ))}
        </Select>

        <Select
          value={inputAssignment.genre}
          onChange={handleChange('genre')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{mr: 2}}
        >
          <MenuItem value=""><em>genre</em></MenuItem>  
          {genres.map((genre: string, index: number) => (
            <MenuItem key={index} value={genre}>{genre}</MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  const configureInputAssignmentView = () => {
    if (submittedText) return null;
    return (
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          {assignmentConfigView()}
        </Grid>

        <Grid container spacing={2} justifyContent="center">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <TextField
                defaultValue={inputText}
                onChange={(e) => setInputText(e.target.value)}
                fullWidth
                multiline
                rows={15}
                sx={{
                  height: '50vh',
                  cursor: 'text',
                  overflow: 'auto',
                  padding: '10px',
                  margin: '10px',
                  whiteSpace: 'pre-wrap', // Ensures text wraps to new lines
                  wordWrap: 'break-word' // Ensures words wrap correctly within the container 
                }}
               />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Button onClick={handleSubmit} style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}>
              Get Suggestions
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const inputAssignmentFeedbackView = () => {
    if (submittedText && inputAssignmentUuid) {
      return (
        <Box sx={{ flexGrow: 1, margin: 2}}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Suggestions initialText={submittedText} inputAssignmentUuid={inputAssignmentUuid} />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Button onClick={handleBack} style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}>
              Back to Assignment
            </Button>
          </Grid>
        </Box>
      )
    }
  }

  return (
    <React.Fragment>
      <SharedAppBar returnUrl="/" />
      <Box sx={{pt: 15}}>
        <Grid container spacing={2} justifyContent="center" sx={{mb: 3}}>
          <ButtonGroup size="large" aria-label="Basic button group">
            <Button>{en.inputAssignment.assignmentMode}</Button>
            <Button>{en.inputAssignment.chatMode}</Button>
          </ButtonGroup>
        </Grid>
        {configureInputAssignmentView()}
        {inputAssignmentFeedbackView()}
      </Box>
    </React.Fragment>
  );
}

export default withAuthenticationRequired(withRoot(Main),{
  onRedirecting: () => <CenteredLoadingSpinner />
});
