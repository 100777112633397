import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// Third Party
import { Link } from 'react-router-dom';

// Internal
import en from '../data/en.json';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface InkBowlPlusModalProps {
  open: boolean;
  handleClose: () => void;
}

export default function InkBowlPlusModal(props: InkBowlPlusModalProps) {
	return (
		<Modal
			open={props.open}
			onClose={props.handleClose}
		>
			<Box sx={style}>
        <Typography variant="h6" component="h2">
          {en.subscription.InkBowlPlusTitle}
        </Typography>
        <Typography sx={{ mt: 2, whiteSpace: "pre" }}>
          {en.subscription.InkBowlPlusBenefits}
        </Typography>
        <Button variant="contained" size="large" sx={{mt: 2}} component={Link} to={process.env.REACT_APP_STRIPE_PAYMENT_LINK!}>
          {en.subscription.signUpForInkBowlPlus}
        </Button>
			</Box>
		</Modal>
	);
}
