import * as React from 'react';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Third Party
import { User as Auth0User } from '@auth0/auth0-react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from "react-router-dom";

// Internal
import en from '../../../../data/en.json';
import { CREATE_SCHOOL_CLASS, DELETE_SCHOOL_CLASS } from '../../../../data/graphql/mutations';
import { GET_SCHOOL_CLASSES, GET_USER_PROFILE_BY_ID } from '../../../../data/graphql/queries';
import { UserType, SchoolClassType } from '../../../../data/types';
import InkBowlPlusModal from '../../../../components/InkBowlPlusModal';

interface EssayCraftSchoolClassesProps {
  user: Auth0User
}

export default function EssayCraftSchoolClasses(props: EssayCraftSchoolClassesProps) {
  const [ newClassModalOpen, setNewClassModalOpen ] = React.useState(false);
  const [ user, setUser ] = React.useState<UserType | null>(null);
  const [ schoolClassName, setSchoolClassName ] = React.useState("");
  const [ professorName, setProfessorName ] = React.useState("");
  const [ schoolClasses, setSchoolClasses ] = React.useState<SchoolClassType[]>([]); 
  const [ snackbarMessage, setSnackbarMessage ] = React.useState<null|string>(null);
  const [ showInkbowlPlusModal, setShowInkbowlPlusModal ] = React.useState<boolean>(false);

  const { data: SchoolClassesData } = useQuery(GET_SCHOOL_CLASSES, {
    variables: { user_auth0_id: props.user.sub },
  });

  const [createSchoolClass, { error: CreateSchoolClassError, data: CreateSchoolClassData }] 
    = useMutation(CREATE_SCHOOL_CLASS);

  const [deleteSchoolClass, { error: DeleteSchoolClassError, data: DeleteSchoolClassData }] 
    = useMutation(DELETE_SCHOOL_CLASS);

  const { data: UserProfileData } = useQuery(GET_USER_PROFILE_BY_ID, {
    variables: { user_auth0_id: props.user.sub },
  });

  React.useEffect(() => {
    if (UserProfileData) { setUser(UserProfileData.userProfileById as UserType); }
  }, [UserProfileData])

  React.useEffect(() => {
    if (CreateSchoolClassData) {
      setSchoolClasses([CreateSchoolClassData.createSchoolClass, ...schoolClasses]);
      setNewClassModalOpen(false);
    }
  }, [CreateSchoolClassData]);

  React.useEffect(() => {
    if (DeleteSchoolClassData) {
      setSchoolClasses(schoolClasses.filter((schoolClass: SchoolClassType) =>
        schoolClass.uuid !== DeleteSchoolClassData.deleteSchoolClass.uuid)
      );
      setSnackbarMessage(en.userDashboard.essayCraftSection.classesView.deleteClassSuccess);
    }
  }, [DeleteSchoolClassData]);

  React.useEffect(() => {
    if (SchoolClassesData) { setSchoolClasses(SchoolClassesData.schoolClasses); }
  }, [SchoolClassesData]);

  const handleNewClassModalOpen = () => {
    if (schoolClasses.length > 1 && user && user.isPremiumUser == false) {
      setShowInkbowlPlusModal(true);
    } else {
      setNewClassModalOpen(true);
    }
  } 
  const handleNewClassModalClose = () => setNewClassModalOpen(false);

  const handleCreateSchoolClass = () => {
    createSchoolClass({ variables: {
      name: schoolClassName,
      professor_name: professorName,
      user_auth0_id: props.user.sub
    }});
  }

  const handleDeleteSchoolClass = (schoolClassUuid: string) => {
    deleteSchoolClass({ variables: { uuid: schoolClassUuid } });
  }

  const newClassModal = (
    <Modal
      open={newClassModalOpen}
      onClose={handleNewClassModalClose}
    >
      <Box sx={{
        display: "flex", flexDirection: "column", position: 'absolute',
        top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
        width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
      }}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mb: 2}}>
          {en.userDashboard.essayCraftSection.classesView.createNewClass}
        </Typography>

        <TextField
          label={en.userDashboard.essayCraftSection.classesView.className}
          onChange={(e) => setSchoolClassName(e.target.value)}
          value={schoolClassName}
          variant="outlined"
          sx={{mb: 2}}
        />
        <TextField
          label={en.userDashboard.essayCraftSection.classesView.professorName}
          onChange={(e) => setProfessorName(e.target.value)}
          value={professorName}
          variant="outlined"
          sx={{mb: 2}}
        />

        <Button variant="contained" color="primary" sx={{mt: 2}}
          onClick={() => handleCreateSchoolClass()} disabled={!schoolClassName || !professorName}
        >
          {en.userDashboard.essayCraftSection.classesView.createNewClass}
        </Button>
      </Box>
    </Modal>    
  )

  const classesView = (
    <Box sx={{height: "35vh", mt: 2}}>
      <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", mb: 2}}>
        <Typography variant="h6" noWrap component="div" sx={{color: "black", mr: 5}}>
          {en.userDashboard.essayCraftSection.classesView.title}
        </Typography>
        {user &&
        <Button variant="outlined" color="primary" onClick={() => handleNewClassModalOpen() }>
          {en.userDashboard.essayCraftSection.classesView.createNewClass}
        </Button>}
        <Tooltip title={en.userDashboard.essayCraftSection.classesView.createNewClassTip}>
          <InfoIcon sx={{ml: 2, color: "black"}} />
        </Tooltip>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row"}}>
        {schoolClasses.map((schoolClass: SchoolClassType, index: number) => (
          <Card sx={{ minWidth: 275, m: 1 }} key={index}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {en.userDashboard.essayCraftSection.classesView.className}
              </Typography>

              <Typography variant="h5" component="div">
                {schoolClass.name}
              </Typography>

              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {en.userDashboard.essayCraftSection.classesView.professorName}: {schoolClass.professorName}
              </Typography>

              <Typography variant="body2">
                more details TBD
                <br />
                {'"e.g. number of assignments"'}
              </Typography>
            </CardContent>
          
            <CardActions>
              <Link key={schoolClass.uuid} to={`/classes/${schoolClass.uuid}`} style={{ textDecoration: 'none', color: 'black' }}>
                <Button size="small">{en.common.view}</Button>
              </Link>
              <Button size="small" sx={{"color": "red"}} onClick={() => handleDeleteSchoolClass(schoolClass.uuid)}>{en.common.remove}</Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  )

  const snackbarMessageView = (
    <Snackbar
      open={snackbarMessage !== null}
      autoHideDuration={3000} 
      message={snackbarMessage}
      onClose={() => setSnackbarMessage(null)}
    />
  )

  return (
    <React.Fragment>
      <InkBowlPlusModal open={showInkbowlPlusModal} handleClose={() => setShowInkbowlPlusModal(false)} />
      {snackbarMessageView}
      {newClassModal}
      {classesView}
    </React.Fragment>
  )
}
