import { gql } from '@apollo/client';


export const GET_ADMIN_POSTS = gql `
  query admin_posts (
    $paging_offset: Int!,
    $paging_limit: Int!
  ) {
    adminPosts(
      pagingOffset: $paging_offset,
      pagingLimit: $paging_limit 
    ) {
      id
      text
      status
      imageUrl
      user {
        handle
        profile
      }
      createdAt
    }
  }
`

export const GET_USER_PROFILE_BY_ID = gql`
  query user_profile_by_id (
    $user_auth0_id: String!
  ) {
    userProfileById(
      userAuth0Id: $user_auth0_id
    ) {
      id
      auth0Id
      handle
      profile
      verified
      role
      posts { id text imageUrl createdAt }
      publications { id status title headline uuid tags { name } }
      followers { handle profile auth0Id }
      following { handle profile auth0Id }
      isPremiumUser
    }
  }
`


export const GET_USER_PROFILE_BY_HANDLE = gql`
  query user_profile_by_handle (
    $handle: String!
  ) {
    userProfileByHandle(
      handle: $handle
    ) {
      id
      auth0Id
      handle
      profile
      verified
      posts { id text imageUrl createdAt }
      publications { id status title headline uuid tags { name } }
      followers { handle profile auth0Id }
      following { handle profile auth0Id }
      isPremiumUser
    }
  }
`

export const GET_PUBLIC_POSTS = gql `
  query public_posts (
    $user_auth0_id: String!,
    $paging_offset: Int!,
    $paging_limit: Int!
  ) {
    publicPosts(
      userAuth0Id: $user_auth0_id,
      pagingOffset: $paging_offset,
      pagingLimit: $paging_limit 
    ) {
      id
      text
      imageUrl
      status
      comments { content user { handle auth0Id profile } createdAt }
      reactions { reactionType user { handle auth0Id } }
      user {
        handle
        profile
      }
      createdAt
    }
  }
`

export const GET_PUBLICATIONS = gql`
  query publications(
    $paging_offset: Int!,
    $paging_limit: Int!
  ) {
    publications(
      pagingOffset: $paging_offset,
      pagingLimit: $paging_limit
    ) {
      id
      uuid
      title
      userId
      kind
      headline
      coverMedia
      htmlContent
      user {
        profile
      }
      tags {
        name
      }
    }
  }
`

export const GET_RELATED_PUBLICATIONS = gql`
query related_publications(
  $number_of_publications: Int!,
  $publication_uuid: String!
) {
  relatedPublications(
    numberOfPublications: $number_of_publications,
    publicationUuid: $publication_uuid
  ) {
    id
    uuid
    title
    userId
    kind
    headline
    coverMedia
    user {
      profile
    }
    tags {
      name
    }
  }
}

`

export const GET_ADMIN_PUBLICATIONS = gql`
  query admin_publications(
    $paging_offset: Int!,
    $paging_limit: Int!
  ) {
    adminPublications(
      pagingOffset: $paging_offset,
      pagingLimit: $paging_limit
    ) {
      uuid
      title
      userId
      status
      kind
      headline
      coverMedia
      media
      htmlContent
      user {
        profile
      }
      tags {
        name
      }
    }
  }
`

export const GET_TAGS = gql`
  query tags(
    $featured: Boolean!
  ) {
    tags(
      featured: $featured
    ) {
      id
      name
    }
  }
`

export const GET_MY_PUBLICATIONS = gql`
  query myPublications(
    $user_auth0_id: String!
  ) {
    myPublications(userAuth0Id: $user_auth0_id) {
      uuid
      title
      userId
      kind
      headline
      coverMedia
      status
      user {
        profile
      }
      tags {
        name
      }
    }
  }
`

export const VIEW_PUBLICATION = gql`
  query publication(
    $uuid: String!
  ) {
    publication(uuid: $uuid) {
      uuid
      title
      userId
      status
      kind
      headline
      coverMedia
      media
      htmlContent
      user {
        auth0Id
        profile
      }
      tags {
        name
      }
    }
  }
`

export const GET_SCHOOL_CLASSES = gql`
  query schoolClasses(
    $user_auth0_id: String!
  ) {
    schoolClasses(
      userAuth0Id: $user_auth0_id
    ) {
      uuid
      name
      professorName
    }
  }
`

export const VIEW_SCHOOL_CLASS = gql`
  query schoolClass(
    $uuid: String!
  ) {
    schoolClass(uuid: $uuid) {
      uuid
      name
      professorName
    }
  }
`

export const GET_GRADED_ASSIGNMENTS = gql`
  query gradedAssignments(
    $school_class_uuid: String!
  ) {
    gradedAssignments(
      schoolClassUuid: $school_class_uuid
    ) {
      uuid
      name
      originalText
      comments {
        uuid
        originalText
        commentText
        offsetStart
        offsetEnd
      }
    }
  }
`

export const GET_INPUT_ASSIGNMENTS = gql`
  query inputAssignments(
    $school_class_uuid: String!
  ) {
    inputAssignments(
      schoolClassUuid: $school_class_uuid
    ) {
      uuid
      name
    }
  }
`

export const VIEW_GRADED_ASSIGNMENT = gql`
  query gradedAssignment(
    $uuid: String!
  ) {
    gradedAssignment(uuid: $uuid) {
      uuid
      name
      originalText
      overallFeedback
      grade
      comments {
        uuid
        originalText
        commentText
        offsetStart
        offsetEnd
      }
    }
  }
`

export const VIEW_INPUT_ASSIGNMENT = gql`
  query inputAssignment(
    $uuid: String!
  ) {
    inputAssignment(uuid: $uuid) {
      uuid
      name
      user { profile }
      originalText
      tone
      style
      genre
    }
  }
`
