import React, { useState, useEffect } from 'react';

// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

// Internal
import en from '../../data/en.json';
import CenteredLoadingSpinner from '../../components/CenteredLoadingSpinner';

// Third Party
import Markdown from 'react-markdown';

interface SuggestionsProps {
  initialText: string;
  inputAssignmentUuid: string;
}

interface Suggestion {
  start: number;
  end: number;
  suggestion: string;
  kind: string;
  reason: string;
}

const Suggestions: React.FC<SuggestionsProps> = ({ initialText, inputAssignmentUuid }) => {
  const [text, setText] = useState<string>(initialText);
  const [correctedText, setCorrectedText] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);
  const [showGradeDetails, setShowGradeDetails] = useState<boolean>(false);
  const [appliedSuggestions, setAppliedSuggestions] = useState<Suggestion[]>([]);
  const [overallFeedback, setOverallFeedback] = useState<string>('');
  const [instructorFeedback, setInstructorFeedback] = useState<string>('');
  const [toneStyleGenreFeedback, setToneStyleGenreFeedback] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch suggestions from the backend
    setLoading(true);
    fetch(`${process.env.REACT_APP_INKBOWL_API_HOST}/api/suggestions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + process.env.REACT_APP_INKBOWL_API_TOKEN},
      body: JSON.stringify({ text: initialText, input_assignment_uuid: inputAssignmentUuid }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setCorrectedText(data.updated_text);
        setSuggestions(data.suggestions);
        setOverallFeedback(data.overall_feedback);
        setInstructorFeedback(data.instructor_feedback);
        setToneStyleGenreFeedback(data.tone_style_genre_feedback);
      })
      .catch((err) => {
        console.error('Error fetching suggestions:', err);
      });
  }, [initialText]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_INKBOWL_API_HOST}/api/input_assignments/${inputAssignmentUuid}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + process.env.REACT_APP_INKBOWL_API_TOKEN},
      body: JSON.stringify({ original_text: text })
    }).then((res) => res.json())
      .then((data) => {
        console.log('Updated input assignment:');
      })
      .catch((err) => {
        console.error('Error fetching suggestions:', err);
      });
  }, [text]);

  const unappliedSuggestions = suggestions.filter((s) => !appliedSuggestions.includes(s));

  const handleAcceptAllSuggestions = () => {
    setText(correctedText);
    setAppliedSuggestions(suggestions);
    setSuggestions([]);
    setSelectedSuggestion(null);
  }

  const handleAcceptSuggestion = (suggestion: Suggestion) => {
    // Apply the suggestion
    const before = text.slice(0, suggestion.start);
    const after = text.slice(suggestion.end);
    const newText = before + suggestion.suggestion + after;

    // Calculate length difference
    const lengthDifference = suggestion.suggestion.length - (suggestion.end - suggestion.start);

    // Update offsets of other suggestions
    const updatedSuggestions = suggestions
      .filter((s) => s !== suggestion)
      .map((s) => {
        if (s.start >= suggestion.end) {
          // Adjust suggestions that come after the applied suggestion
          return {
            ...s,
            start: s.start + lengthDifference,
            end: s.end + lengthDifference,
          };
        } else if (s.end <= suggestion.start) {
          // Suggestions before the applied one remain unchanged
          return s;
        } else {
          // Suggestions that overlap with the applied suggestion are invalidated
          return null;
        }
      })
      .filter((s): s is Suggestion => s !== null);

    setText(newText);
    setAppliedSuggestions([...appliedSuggestions, suggestion]);
    setSuggestions(updatedSuggestions);
    setSelectedSuggestion(null);
  };

  const handleRejectSuggestion = (suggestion: Suggestion) => {
    // Remove the suggestion from the list
    setSuggestions(suggestions.filter((s) => s !== suggestion));
    setSelectedSuggestion(null);
  };

  const renderTextWithHighlights = () => {
    const elements: React.ReactNode[] = [];
    let lastIndex = 0;

    // Sort suggestions by start index
    const sortedSuggestions = [...suggestions].sort((a, b) => a.start - b.start);

    sortedSuggestions.forEach((suggestion, index) => {
      if (appliedSuggestions.includes(suggestion)) {
        return;
      }

      // Handle overlapping suggestions
      if (suggestion.start < lastIndex) {
        return;
      }

      const before = text.slice(lastIndex, suggestion.start);
      const highlighted = text.slice(suggestion.start, suggestion.end);

      if (before) {
        elements.push(
          <span key={`text-${lastIndex}`}>
            {before}
          </span>
        );
      }

      elements.push(
        <span
          key={`suggestion-${index}`}
          className={`highlight-${suggestion.kind}`}
          onClick={() => setSelectedSuggestion(suggestion)}
          title={suggestion.reason}
        >
          {highlighted}
        </span>
      );

      lastIndex = suggestion.end;
    });

    // Add the remaining text
    if (text && lastIndex < text.length) {
      elements.push(
        <span key={`text-end`}>
          {text.slice(lastIndex)}
        </span>
      );
    }

    return elements;
  };

  const isSuggestionSelected = (suggestion: Suggestion) => {
    return selectedSuggestion === suggestion;
  }

  const suggestionBackgroundColor = (suggestion: Suggestion) => {
    if (isSuggestionSelected(suggestion)) {

      if (suggestion.kind === 'replace') {
        return '#FF8C00';
      } else if (suggestion.kind === 'add') {
        return '#8FBC8F';
      } else if (suggestion.kind === 'delete') {
        return'#dc9a9a';
      }
    } else {
      return 'white';
    }
  }

  const sugggestsionsView = () => {
    return unappliedSuggestions.map((suggestion, index) => (
      <Card variant="outlined" sx={{ padding: '10px', mb: 2, backgroundColor: suggestionBackgroundColor(suggestion) }} key={index}>
        <CardContent>
          <Typography variant="body2" component="p">
            {suggestion.reason}
          </Typography>
        </CardContent>
        <CardActions>
          <Button color="info" onClick={() => handleAcceptSuggestion(suggestion)}>{en.common.accept}</Button>
          <Button color="info" onClick={() => handleRejectSuggestion(suggestion)}>{en.common.reject}</Button>
        </CardActions>
      </Card>
    ));
  }

  const overallFeedbackView = () => {
    if (overallFeedback) {
      return (
        <Card sx={{p: 2, mb: 2}}>
          <CardContent>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              <Markdown>{overallFeedback}</Markdown>
            </Typography>
          </CardContent>
          <CardActions>
            <Button sx={{display: "none"}}>{en.inputAssignment.viewDetails}</Button>
            <Button color="info" onClick={() => handleAcceptAllSuggestions()}>{en.inputAssignment.acceptAllSuggestions}</Button>
            <Button  onClick={() => setShowGradeDetails(true) }>{en.inputAssignment.howIsPredicatedGradeCalculated}</Button>
          </CardActions>
        </Card>
      )
    }
  }

  const howIsPredicatedGradeCalculatedView = () => {
    return (
			<Modal
				open={showGradeDetails}
				onClose={() => setShowGradeDetails(false)}
			>
        <Box sx={{
          display: "flex", flexDirection: "column", position: 'absolute',
          top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4
        }}>
					<Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {en.inputAssignment.howIsPredicatedGradeCalculatedDescription}
					</Typography>
				</Box>
			</Modal>
    )

  }

  const detailedFeedbackView = () => {
    return null;
  }

  if (loading) { return <CenteredLoadingSpinner />; }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {howIsPredicatedGradeCalculatedView()}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={9}>
          {overallFeedbackView()}
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={9}>
          <div
            className="text-editor"
            style={{
              whiteSpace: 'pre-wrap', // Preserve spaces and line breaks
              fontSize: '16px',
              lineHeight: '1.6',
              fontFamily: 'Arial, sans-serif',
              color: '#333',
            }}
          >
            {renderTextWithHighlights()}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          {sugggestsionsView()}
          <style>{`
            .text-editor {
              white-space: pre-wrap;
              font-size: 16px;
              line-height: 1.6;
              font-family: Arial, sans-serif;
              color: #333;
            }
            .highlight-replace {
              text-decoration: underline;
              color: #FF8C00;
              transition: background-color 0.3s;
            }
            .highlight-replace:hover {
              text-decoration: underline;
              color: #FFEBCD;
              background-color: #FF8C00;
            }
            .highlight-add {
              text-decoration: underline;
              color: #8FBC8F;
              transition: background-color 0.3s;
            }
            .highlight-add:hover {
              text-decoration: underline;
              color: #228B22;
              background-color: #8FBC8F;
            }
            .highlight-delete {
              text-decoration: line-through;
              color: #CD5C5C;
              transition: background-color 0.3s;
            }
            .highlight-delete:hover {
              text-decoration: line-through;
              background-color: #B22222;
              color: #CD5C5C;
            }
          `}</style>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Suggestions;
